<template>
  <v-container>
    <v-card
      color="basil"
      style="margin-top: 0;"
      flat
    >
      <v-card-text>
        <v-list-item>
          <v-list-item-content
            style="margin: 0 auto; max-width: 400px;"
          >
            <div class="mb-4">
              <v-img
                contain
                :src="client.photo"
                :alt="client.name"
                style="margin: 0 auto;"
                width="100px"
                height="100px"
              />
            </div>
            <v-list-item-title
              v-for="(detail) in details"
              v-if="hasPermission('client_' + detail.id + '_view') && (!detail.showHasValue || (detail.showHasValue && client[detail.id]) || (detail.hasValue && detail.value))"
              :key="detail.id"
              cols="12"
              style="margin: 5px 0; display: flex;"
            >
              <span
                class="font-weight-black"
                style="margin-right: 5px;"
                :style="{color: detail.color}"
              >
                {{ detail.title }}:
              </span>
              <span
                v-if="detail.id !== 'id' && detail.id !== 'status' && detail.id !== 'gender' && detail.id !== 'payday' && detail.id !== 'password' && detail.id !== 'feedback_day' && detail.id !== 'paym_info' && detail.id !== 'employee' && detail.id !== 'chat_token' && detail.id !== 'tags' && !detail.hasValue"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ client[detail.id] }}
              </span>
              <span
                v-if="detail.id === 'id'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                <span
                  v-if="status2Enabled && client.status2"
                  class="cursor-hover"
                  @click.stop="openClientStatus2Warning(client)"
                >
                  <i
                    :style="{color: client.status2}"
                    class="mdi mdi-alert-circle"
                  />
                </span>
                {{ client[detail.id] }}
              </span>
              <span
                v-if="detail.id === 'password'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ passwordDecrypted ? passwordDecrypted : '' }}
              </span>
              <span
                v-if="detail.id === 'gender'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ client.gender_label }}
              </span>
              <span
                v-if="detail.id === 'status'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ clientsStatusDict[client[detail.id]] ? clientsStatusDict[client[detail.id]].label : str['n_a'] }}
              </span>
              <span
                v-if="detail.id === 'payday' && user.configurations && user.configurations.payment && user.configurations.payment.payday"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ client[detail.id] }}
                <v-icon
                  v-if="showPaymPri && client.paym_pri"
                  color="error"
                  dark
                  class="cursor-hover ml-1"
                  @click.stop="showInformation(client.paym_pri)"
                >
                  mdi-information
                </v-icon>
              </span>
              <span
                v-if="detail.id === 'feedback_day'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ feedbackRef ? feedbackRef.getClientFeedbackDay(client[detail.id]) : '...' }}
              </span>
              <span
                v-if="detail.id === 'paym_info'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ paymentsViewPermission ? client[detail.id] : client[detail.id].split('(')[0].trim() }}
              </span>
              <span
                v-if="detail.id === 'employee'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ clientEmployees }}
              </span>
              <span
                v-if="detail.id === 'chat_token' && (client[detail.id] === 'no_token' || !client[detail.id])"
                class="font-weight-regular warning--text"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ str['disabled'] }}
              </span>
              <span
                v-if="detail.id === 'chat_token' && client[detail.id] && client[detail.id] !== 'no_token'"
                class="font-weight-regular success--text"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ str['enabled'] }}
              </span>
              <span
                v-if="detail.id === 'tags'"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ client[detail.id] ? client[detail.id].join(', ') : '' }}
              </span>
              <span
                v-if="detail.hasValue"
                class="font-weight-regular"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ detail.value }}
              </span>
              <v-icon
                v-if="detail.edit && detailsToEdit && detailsToEdit.indexOf(detail.id) > -1"
                style="margin-left: 3px; transform: translateY(-1px);"
                @click="openEditDetails(detail)"
                color="warning"
                class="cursor-hover"
              >
                mdi-pencil-box
              </v-icon>
            </v-list-item-title>
            <div v-if="showContentsAccess">
              <v-checkbox
                v-model="contentsAccessValue"
                :label="str['access_contents']"
                @change="changeContentsAccessValue()"
                class="form-field-checkbox contents-access-checkbox"
                color="secondary"
                hide-details
              />
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-col
          v-if="showEvolutionPhotosPermission"
          cols="12"
          class="pt-0 row-flex-align-center"
        >
          <b>
            <v-checkbox
              v-model="parent.ptNotes.evolution_photos_permission_requested"
              :label="str['evolution_photos_permission']"
              class="form-field-checkbox"
              @change="parent.savePtNotes()"
              color="success"
              style="margin: 0;"
              hide-details
            />
          </b>
        </v-col>

        <v-col
          v-if="user.configurations && user.configurations.payment && user.configurations.payment.payday && hasPermission('payday_edit') && (!client.paym_sub || (clientSubscription && clientSubscription.show_update_payday) || showUpdatePaydayWithSubscription)"
          cols="12"
          class="pt-3 text-center"
        >
          <v-btn
            color="deep-orange darken-1"
            class="mr-0"
            style="min-width: 250px;"
            @click="openPayday"
          >
            {{ str['update_payment_date'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="user && user.configurations && user.configurations.feedback && (user.configurations.feedback.feedback_day || user.configurations.feedback.feedback_weekday) && hasPermission('client_feedback_day_edit')"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="yellow darken-3"
            class="mr-0"
            style="min-width: 250px;"
            @click="feedbackRef && feedbackRef.openFeedbackDialog()"
          >
            {{ user.configurations.feedback.feedback_weekday ? str['update_feedback_day'] : str['update_feedback_date'] }}
          </v-btn>
        </v-col>

        <div v-if="user && user.configurations && user.configurations.feedback && user.configurations.feedback.tabs">
          <v-col
            v-for="fdType in user.configurations.feedback.tabs"
            v-if="fdType.update_button"
            :key="fdType.id"
            cols="12"
            class="pt-0 text-center"
          >
            <v-btn
              color="yellow darken-3"
              class="mr-0"
              style="min-width: 250px; max-width: 250px; height: auto;"
              @click="feedbackRef && feedbackRef.openFeedbackDialog(fdType)"
            >
              <div style="min-width: 250px; max-width: 250px; white-space: break-spaces;">
                <div>
                  {{ str[fdType.update_button] ? str[fdType.update_button] : fdType.update_button }}
                </div>
                <div
                  v-if="feedbackRef && feedbackRef.getClientFeedbackTypeValue(fdType)"
                  style="margin-top: 5px; font-weight: bold;"
                >
                  {{ feedbackRef.getClientFeedbackTypeValue(fdType) }}
                </div>
              </div>
            </v-btn>
          </v-col>
        </div>

        <v-col
          v-if="client.status === 3 && user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_day"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="success darken-3"
            class="mr-0"
            style="min-width: 250px;"
            @click="activateClient"
          >
            {{ str['activate_client'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showAssignEmployeesButton && employeesList.length"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="orange darken-3"
            class="mr-0"
            style="min-width: 250px;"
            @click="openAssignEmployees"
          >
            {{ str['assign_employee'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showChatButton"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="blue"
            class="mr-0"
            style="min-width: 250px;"
            @click="openClientChat"
          >
            {{ str['send_message'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showNewFeedbackButton"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="warning"
            class="mr-0"
            style="min-width: 250px;"
            @click="copyFeedbackLink"
          >
            {{ showNewFeedbackButton.text }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showNotificationButton"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="blue"
            class="mr-0"
            style="min-width: 250px;"
            @click="openSendNotification"
          >
            {{ str['send_notification'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showSendInitialFeedback"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="warning"
            class="mr-0"
            style="min-width: 250px;"
            @click="sendInitialFeedback"
          >
            {{ str['remove_initial_physical_evaluation'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showSyncSubscription"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="warning"
            class="mr-0"
            style="min-width: 250px; padding-left: 10px !important; padding-right: 10px !important;"
            @click="syncSubscription()"
          >
            {{ str[showSyncSubscription.text] ? str[showSyncSubscription.text] : showSyncSubscription.text }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showChangePassword"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="error"
            class="mr-0"
            style="min-width: 250px; padding-left: 10px !important; padding-right: 10px !important;"
            @click="changePassword()"
          >
            {{ str['set_new_password'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="user && user.configurations && user.configurations.clients && user.configurations.clients.show_back_link && hasPermission('generate_return_feedback_link')"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="cyan darken-3"
            class="mr-0"
            style="min-width: 250px;"
            @click="copyReturnLink"
          >
            {{ user.configurations.clients.show_back_link.text }}
          </v-btn>
        </v-col>

        <v-col
          v-if="user && user.configurations && user.configurations.clients && user.configurations.clients.show_new_plans_link && hasPermission('generate_return_feedback_link')"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="cyan darken-3"
            class="mr-0"
            style="min-width: 250px;"
            @click="copyNewPlansLink"
          >
            {{ user.configurations.clients.show_new_plans_link.text }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showWhatsappApi && hasPermission('client_open_whatsapp')"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="green darken-3"
            class="mr-0"
            style="min-width: 250px;"
            @click="openWhatsappApi"
          >
            {{ str['open_whatsapp_api'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="user && user.configurations && user.configurations.clients && user.configurations.clients.show_whatsapp && hasPermission('client_open_whatsapp')"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            :color="showWhatsappApi ? 'gray' : 'green darken-3'"
            class="mr-0"
            style="min-width: 250px;"
            @click="openWhatsapp"
          >
            {{ str['open_whatsapp'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="user && user.configurations && user.configurations.clients && user.configurations.clients.show_whatsapp && hasPermission('client_open_whatsapp')"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            :color="showWhatsappApi ? 'gray' : 'green darken-3'"
            class="mr-0"
            style="min-width: 250px;"
            @click="copyWhatsapp"
          >
            {{ str['copy_whatsapp'] }}
          </v-btn>
        </v-col>

        <v-col
          v-if="showAddPdf"
          cols="12"
          class="pt-0 text-center"
        >
          <v-btn
            color="purple darken-3"
            class="mr-0"
            style="min-width: 250px;"
            @click="openPdf()"
          >
            {{ showAddPdf.button_text ? (str[showAddPdf.button_text] ? str[showAddPdf.button_text] : showAddPdf.button_text) : str['add_pdf'] }}
          </v-btn>
        </v-col>

        <div
          class="pt-3"
          style="margin: 0 auto; max-width: 400px;"
        >
          <div>
            <v-textarea
              v-model="parent.ptNotes.details_notes"
              :label="str['notes']"
              :rows="3"
              outlined
              hide-details
            />
          </div>
          <div class="text-center mt-2">
            <v-btn
              color="secondary"
              class="mr-0"
              @click="parent.savePtNotes()"
            >
              {{ str['save_notes'] }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogPayday"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['update_payment_date'] }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-menu
            v-model="datepickerPaydayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="payday"
                :label="str['payment_date']"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="payday"
              style="margin: 0;"
              @change="datepickerPaydayMenu = false"
              :locale="datepickerLanguage"
            />
          </v-menu>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogPayday = false"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="updatePayday(payday)"
          >
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDetail"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['edit_data'] }}
          </span>
        </v-card-title>
        <v-card-text v-if="detailToEdit">
          <v-container>
            <v-row>
              <v-text-field
                v-if="detailToEdit.field.type === 'input' && detailToEdit.id !== 'email'"
                v-model="detailToEdit.value"
                v-bind:label="detailToEdit.title"
              />
              <v-text-field
                v-if="detailToEdit.field.type === 'input' && detailToEdit.id === 'email'"
                v-model="detailToEdit.value"
                v-bind:label="detailToEdit.title"
                @input="detailToEdit.value = detailToEdit.value.toLowerCase()"
              />
              <v-autocomplete
                v-if="detailToEdit.field.type === 'select'"
                v-bind:label="detailToEdit.title"
                item-text="label"
                item-value="value"
                v-model="detailToEdit.value"
                :items="detailToEdit.field.items"
                :no-data-text="str['no_data']"
                :search-input.sync="detailToEdit.searchInput"
                @change="detailToEdit.searchInput=''"
              />
              <v-autocomplete
                v-if="detailToEdit.field.type === 'multiselect'"
                v-bind:label="detailToEdit.title"
                item-text="label"
                item-value="value"
                v-model="detailToEdit.value"
                :items="detailToEdit.field.items"
                :no-data-text="str['no_data']"
                :multiple="true"
                :search-input.sync="detailToEdit.searchInput"
                @change="detailToEdit.searchInput=''"
              />
              <v-menu
                v-if="detailToEdit.field.type === 'datepicker'"
                v-model="datepickerDetailToEditMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailToEdit.value"
                    v-bind:label="detailToEdit.title"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="detailToEdit.value"
                  style="margin: 0;"
                  @change="datepickerDetailToEditMenu = false"
                  :locale="datepickerLanguage"
                />
              </v-menu>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="cancelEditDetails"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            v-if="detailToEdit && detailToEdit.field && detailToEdit.field.empty"
            color="warning"
            @click="detailToEdit.value = ''; saveEditDetails()"
          >
            {{ str['clear'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveEditDetails"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNotification"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['send_notification'] }}
          </span>
        </v-card-title>
        <v-card-text v-if="notification">
          <v-container>
            <v-text-field
              v-model="notification.title"
              :label="str['notification_title']"
            />
            <v-textarea
              v-model="notification.body"
              :label="str['notification_message']"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeSendNotification"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="confirmSendNotification"
          >
            {{ str['send'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="assignEmployeesData"
      v-model="dialogAssignEmployees"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['assing_employee'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-autocomplete
              v-model="assignEmployeesData.employee1"
              :label="str['employee']"
              item-text="name"
              item-value="id"
              :items="employeesList"
              :no-data-text="str['no_data']"
            />
            <v-autocomplete
              v-if="!assignOnlyOneEmployee"
              v-model="assignEmployeesData.employee2"
              :label="str['employee']"
              item-text="name"
              item-value="id"
              :items="employeesList"
              :no-data-text="str['no_data']"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeAssignEmployees"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="confirmAssignEmployees"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showAddPdf"
      v-model="dialogPdf"
      persistent
      max-width="750px"
    >
      <v-card class="pt-8">
        <div>
          <div class="row-align-center">
            <div>
              <v-btn
                v-if="dialogPdfValue"
                color="error"
                class="mr-0"
                @click="removePdf()"
              >
                {{ str['remove'] }}
              </v-btn>
            </div>
            <div
              style="padding: 20px; display: flex; justify-content: center; align-items: center;"
            >
              <v-btn
                color="blue"
                class="mr-0"
              >
                {{ dialogPdfValue ? str['replace_pdf'] : str['add_pdf'] }}
              </v-btn>
              <input
                id="input-pdf-value"
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                @change="loadPdf"
                style="opacity: 0; position: absolute; height: 45px; width: 160px;"
              />
            </div>
          </div>
          <div style="padding: 0 0 20px;">
            <iframe
              v-if="dialogPdfValue"
              v-bind:src="dialogPdfValue"
              style="width: 100%; height: 50vh;"
            />
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closePdf()"
          >
            {{ str['close'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="savePdf()"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showWhatsappApi"
      v-model="dialogSelectWhatsappApi"
      persistent
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['whatsapp'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                v-for="url in showWhatsappApi.urls"
                :key="url.id"
                cols="12"
              >
                <v-btn
                  :color="url.color"
                  @click="selectWhatsappApi(url)"
                  class="row-full-width"
                >
                  {{ str[url.name] ? str[url.name] : url.name }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="default"
                  @click="dialogSelectWhatsappApi = false"
                  class="row-full-width"
                >
                  {{ str['close'] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showWhatsappApi"
      v-model="dialogWhatsappApi"
      persistent
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['whatsapp'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-btn
                  color="success"
                  @click="openWhatsappApiMessage()"
                  class="row-full-width"
                >
                  {{ str['send_message'] }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="blue"
                  @click="openWhatsappApiLink()"
                  class="row-full-width"
                >
                  {{ str['open_link'] }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="default"
                  @click="closeWhatsappApi()"
                  class="row-full-width"
                >
                  {{ str['close'] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showWhatsappApi"
      v-model="dialogWhatsappApiMessage"
      persistent
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['whatsapp'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                v-for="item in whatsappApiFields"
                :key="item.id"
                cols="12"
              >
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title"
                  :disabled="item.disabled ? true : false"
                  hide-details
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeWhatsappApiMessage()"
          >
            {{ str['close'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="confirmWhatsappApiMessage()"
          >
            {{ str['send_message'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNotificationModes"
      persistent
      max-width="450px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['send'] }}
          </span>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-container>
            <v-row>
              <v-col
                v-if="notificationsModes.indexOf('push_notification') > -1"
                cols="12"
              >
                <v-btn
                  color="success"
                  @click="sendPushNotification()"
                  class="row-full-width label-text-break"
                >
                  {{ str['push_notification'] }}
                </v-btn>
              </v-col>
              <v-col
                v-if="notificationsModes.indexOf('internal_notification') > -1"
                cols="12"
              >
                <v-btn
                  color="warning"
                  @click="sendInternalNotification()"
                  class="row-full-width label-text-break"
                >
                  {{ str['internal_notification'] }}
                </v-btn>
              </v-col>
              <v-col
                v-if="notificationsModes.indexOf('email') > -1"
                cols="12"
              >
                <v-btn
                  color="blue"
                  @click="sendEmail()"
                  class="row-full-width label-text-break"
                >
                  {{ str['email'] }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="default"
                  @click="dialogNotificationModes = false"
                  class="row-full-width"
                >
                  {{ str['cancel'] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <client-feedback
      ref="feedback"
      :parent="parent"
      :client="client"
      :mountedCallback="clientFeedbackMounted"
    />
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import ChatApi from '@/services/ChatApi'
  import Utils from '@/services/Utils'
  import axios from 'axios'

  export default {
    name: 'client-details',
    props: ['parent', 'client'],
    components: {
      ClientFeedback: () => import('@/views/dashboard/components/client/Feedback'),
    },
    data() {
      const user = Utils.getUser()

      let showChatButton = false
      if (user && user.configurations &&
        ((user.configurations.menu_items && user.configurations.menu_items.indexOf('chat') > -1) || user.configurations.chat) &&
        Utils.hasPermission('chat_view')
      ) {
        showChatButton = true
      }

      let showContentsAccess = false
      if (user && user.configurations && user.configurations.contents && user.configurations.contents.has_client_access) {
        showContentsAccess = true
      }

      let showNotificationButton = false
      if (((user && user.configurations && user.configurations.menu_items && user.configurations.menu_items.indexOf('notifications') > -1) || (user && user.configurations && user.configurations.clients && user.configurations.clients.show_send_notifications)) && Utils.hasPermission('notifications_view')) {
        showNotificationButton = true
      }

      let showAssignEmployeesInfo = false
      if (user && user.configurations && user.configurations.employees && user.configurations.employees.assign_clients) {
        showAssignEmployeesInfo = true
      }

      let showAssignEmployeesButton = false
      if (user && user.configurations && user.configurations.employees && user.configurations.employees.assign_clients && Utils.hasPermission('assign_clients_view')) {
        showAssignEmployeesButton = true
      }

      let detailsToEdit = []
      if (user && user.configurations && user.configurations.client_fields_edit) {
        detailsToEdit = user.configurations.client_fields_edit
      }

      let status2Enabled = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_status2) {
        status2Enabled = user.configurations.clients.show_status2
      }

      let showPaymPri = false
      if (window.useHotmart && user && !Utils.isEmployee(user.type)) {
        showPaymPri = true
      }

      let showFeedbackCalc = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_calc) {
        showFeedbackCalc = true
      }

      let showDefaultWeeklyCardio = false
      if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_default_weekly_cardio) {
        showDefaultWeeklyCardio = true
      }

      let showTags = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_tags) {
        showTags = true
      }

      let assignOnlyOneEmployee = false
      if (user && user.configurations && user.configurations.employees && user.configurations.employees.assing_only_one) {
        assignOnlyOneEmployee = true
      }

      let useFirstPhysicalEvaluationHeight = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.use_first_physical_evaluation_height) {
        useFirstPhysicalEvaluationHeight = true
      }

      let showSyncSubscription = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_sync_subscription) {
        showSyncSubscription = user.configurations.clients.show_sync_subscription
      }

      let showChangePassword = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_change_password && Utils.hasPermission('client_password_change')) {
        showChangePassword = true
      }

      let showAddPdf = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_add_pdf) {
        showAddPdf = user.configurations.clients.show_add_pdf
      }

      let showEvolutionPhotosPermission = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_evolution_photos_permission) {
        showEvolutionPhotosPermission = true
      }

      let showNewFeedbackButton = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_new_feedback_button) {
        showNewFeedbackButton = user.configurations.clients.show_new_feedback_button
      }

      let showWhatsappApi = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_whatsapp_api) {
        showWhatsappApi = user.configurations.clients.show_whatsapp_api
      }

      let showUpdatePaydayWithSubscription = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_update_payday_with_subscription) {
        showUpdatePaydayWithSubscription = true
      }

      let notificationsModes = ['push_notification']
      if (user && user.configurations && user.configurations.notifications && user.configurations.notifications.modes) {
        notificationsModes = user.configurations.notifications.modes
      }

      let gatewayPaymentType = null
      if (window.gatewayType) {
        const paymentTypes = Utils.getPaymentTypes()
        gatewayPaymentType = paymentTypes ? paymentTypes.find(function (p) {
          return p.value === window.gatewayType
        }) : null
      }

      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        hasPermission: Utils.hasPermission,
        user: user,
        showChatButton: showChatButton,
        showContentsAccess: showContentsAccess,
        showNotificationButton: showNotificationButton,
        showAssignEmployeesInfo: showAssignEmployeesInfo,
        showAssignEmployeesButton: showAssignEmployeesButton,
        status2Enabled: status2Enabled,
        showPaymPri: showPaymPri,
        showFeedbackCalc: showFeedbackCalc,
        showDefaultWeeklyCardio: showDefaultWeeklyCardio,
        showTags: showTags,
        assignOnlyOneEmployee: assignOnlyOneEmployee,
        useFirstPhysicalEvaluationHeight: useFirstPhysicalEvaluationHeight,
        showSyncSubscription: showSyncSubscription,
        showChangePassword: showChangePassword,
        showAddPdf: showAddPdf,
        showEvolutionPhotosPermission: showEvolutionPhotosPermission,
        showNewFeedbackButton: showNewFeedbackButton,
        showWhatsappApi: showWhatsappApi,
        showUpdatePaydayWithSubscription: showUpdatePaydayWithSubscription,
        notificationsModes: notificationsModes,
        gatewayPaymentType: gatewayPaymentType,
        feedbackRef: null,
        paymentsViewPermission: Utils.hasPermission('payments_view'),
        details: [],
        detailsToEdit: detailsToEdit,
        detailToEdit: null,
        clientsStatusDict: this.getClientsStatusDict(),
        datepickerDetailToEditMenu: null,
        dialogDetail: false,
        passwordDecrypted: null,
        contentsAccessValue: false,
        dialogPayday: false,
        payday: null,
        datepickerPaydayMenu: null,
        statusMap: {
          active: 0,
          suspended: 1,
          blocked: 2,
          activateClient: 4,
        },
        notification: null,
        dialogNotification: false,
        dialogAssignEmployees: false,
        assignEmployeesData: null,
        employeesList: [],
        foods: {},
        showSendInitialFeedback: false,
        dialogPdf: false,
        dialogPdfValue: null,
        clientSubscription: null,
        dialogSelectWhatsappApi: false,
        dialogWhatsappApi: false,
        dialogWhatsappApiMessage: false,
        whatsappApiFields: [],
        dialogNotificationModes: false,
      }
    },
    computed: {
      clientEmployees: function () {
        const self = this;
        if (!this.employeesList.length) {
          return window.strings['processing']
        }
        let str = ''
        if (this.client.employee1) {
          const employee1 = this.getEmployee(this.client.employee1)
          if (employee1) {
            str += employee1.name
          }
        }
        if (this.client.employee2) {
          const employee2 = this.getEmployee(this.client.employee2)
          if (employee2) {
            str += (str === '' ? '' : ' | ') + employee2.name
          }
        }
        return str === '' ? window.strings['n_a'] : str
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.main()
    },
    methods: {
      main: function () {
        this.getDetailsFields()

        if (this.client.photo === require('@/assets/user.svg') ||
          this.client.photo === require('@/assets/loading.gif')
        ) {
          this.getClientPhoto()
        }

        if (window.showPaydayAlert) {
          this.checkPayday()
          window.showPaydayAlert = null
        }

        if (this.user && this.user.configurations && this.user.configurations.clients && this.user.configurations.clients.show_password && this.hasPermission('client_password_view')) {
          this.decryptPassword()
        }

        if (this.showContentsAccess) {
          this.refreshContentsAccessValue()
        }

        if (this.showAssignEmployeesInfo) {
          this.getEmployees()
        }

        if (this.gatewayPaymentType && this.gatewayPaymentType.get_client_subscription) {
          this.getClientSubscription()
        }

        this.validateInitialFeedback()
        this.refreshFeedbackDateCalc()
      },
      refresh: function () {
        this.refreshFeedbackDateCalc()
      },
      getDetailsFields: function () {
        const self = this
        const config = this.user.configurations
        const order = config && config.client_fields_order ? config.client_fields_order : ['chat_token', 'id', 'name', 'email', 'password', 'employee', 'birth_date', 'age', 'gender', 'height', 'mobile_phone', 'status', 'paym_info', 'paym_cancel_dt', 'occupation', 'identity_card', 'tax_id_number', 'country', 'address', 'postal_code', 'locality', 'goal_txt', 'register_date', 'feedback_aux', 'feedback_day', 'phy_eval', 'payday', 'chat_last_msg', 'last_login_dt', 'last_feedback_valid', 'tags', 'position', 'device_model']
        const details = [{
          id: 'id',
          title: window.strings['id'],
        }, {
          id: 'name',
          title: window.strings['name'],
          edit: {
            type: 'input'
          },
        }, {
          id: 'email',
          title: window.strings['email'],
          edit: {
            type: 'input'
          },
        }, {
          id: 'birth_date',
          title: window.strings['birth_date'],
          edit:{
            keysToUpdate:['age'],
            type: 'datepicker'
          },
        }, {
          id: 'age',
          title: window.strings['age'],
        }, {
          id: 'gender',
          title: window.strings['gender'],
          edit: {
            keysToUpdate: ['gender_label'],
            type: 'select',
            items: Utils.getGenders()
          },
        }, {
          id: 'mobile_phone',
          title: window.strings['contact'],
          edit: {
            type: 'input'
          },
        }, {
          id: 'register_date',
          title: window.strings['validation_date'],
        }, {
          id: 'status',
          title: window.strings['status'],
          edit: {
            type: 'select',
            items: Utils.getUserStatus(true)
          },
        }, {
          id: 'paym_info',
          title: window.strings['plan'],
          showHasValue: true,
        }, {
          id: 'paym_cancel_dt',
          title: window.strings['subscription_cancelled_date'],
          showHasValue: true,
        }, {
          id: 'occupation',
          title: window.strings['occupation'],
          edit: {
            type: 'input'
          },
          showHasValue: true,
        }, {
          id: 'country',
          title: window.strings['country'],
          showHasValue: true,
          edit: {
            type: 'select',
            items: []
          },
        }, {
          id: 'locality',
          title: window.strings['postal_code'],
          edit: {
            type: 'input'
          },
          showHasValue: true,
        }, {
          id: 'identity_card',
          title: window.strings['identity_card'],
          edit: {
            type: 'input'
          },
          showHasValue: true,
        }, {
          id: 'address',
          title: window.strings['address'],
          edit: {
            type: 'input'
          },
          showHasValue: true,
        }, {
          id: 'last_login_dt',
          title: window.strings['last_login_date'],
          showHasValue: true,
        }]

        if (config && config.clients && config.clients.show_password && this.hasPermission('client_password_view')) {
          details.push({
            id: 'password',
            title: window.strings['password'],
            edit: {
              type: 'input'
            },
          })
        }

        if (this.showAssignEmployeesInfo) {
          details.push({
            id: 'employee',
            title: window.strings['employee'],
          })
        }

        if (config && config.payment && config.payment.payday) {
          details.push({
            id: 'payday',
            title: window.strings['payment_date'],
          })
        }

        if (config && config.feedback && config.feedback.feedback_aux) {
          details.push({
            id: 'feedback_aux',
            title: window.strings['feedback_aux'],
            edit: {
              type: 'datepicker',
              empty: true,
              callback: function () {
                self.refreshFeedbackDateCalc()
              }
            },
          })
        }

        if (config && config.feedback && !config.feedback.save_feedback_aux && (config.feedback.feedback_day || config.feedback.feedback_weekday)) {
          details.push({
            id: 'feedback_day',
            title: config.feedback.feedback_weekday ? window.strings['feedback_day'] : window.strings['feedback_date'],
          })
        }

        if (config && (config.menu_items.indexOf('chat') > -1 || config.chat)) {
          details.push({
            id: 'chat_last_msg',
            title: window.strings['last_message_date'],
            showHasValue: true,
          })
        }

        if (config && config.feedback && config.feedback.feedback_calc) {
          details.push({
            id: 'phy_eval',
            title: window.strings['feedback_date'],
            showHasValue: true,
            hasValue: true,
          })
        }

        if (config && config.client_fields) {
          config.client_fields.forEach(function (item) {
            const index = details.findIndex(function (dt) {
              return dt.id === item.id
            })
            if ((!item.admin || (item.admin && !Utils.isEmployee(self.user.type))) &&
              (!item.permission || (item.permission && Utils.hasPermission(item.permission)))
            ) {
              if (index > -1) {
                if (item.text !== undefined) {
                  details[index].title = window.strings[item.text] ? window.strings[item.text] : item.text
                }
                if (item.showHasValue !== undefined) {
                  details[index].showHasValue = item.showHasValue
                }
                if (item.edit !== undefined) {
                  details[index].edit = item.edit
                }
                if (item.color !== undefined) {
                  details[index].color = item.color
                }
              } else {
                const obj = {
                  id: item.id,
                  title: window.strings[item.text] ? window.strings[item.text] : item.text,
                  showHasValue: item.showHasValue ? true : false,
                  edit: item.edit,
                  color: item.color
                }
                if (obj.edit && obj.edit.enums && Utils[obj.edit.enums]) {
                  obj.edit.items = Utils[obj.edit.enums]()
                }
                if (item.firstPosition) {
                  details.unshift(obj)
                } else {
                  details.push(obj)
                }
              }
            }
          })
        }

        let hasHeightField = false
        details.forEach(function (dt) {
          if (order.indexOf(dt.id) === -1) {
            order.push(dt.id)
          }
          if (dt.edit && (dt.edit.type === 'select' || dt.edit.type === 'multiselect')) {
            dt.searchInput = ''
          }
          if (dt.id === 'height') {
            hasHeightField = true
          }
        })

        this.details = details.sort((a, b) => {
          const itemA = order.indexOf(a.id);
          const itemB = order.indexOf(b.id);
          return itemA - itemB;
        })

        if (hasHeightField) {
          this.refreshHeightValue()
        }
      },
      refreshHeightValue: function () {
        const self = this
        if (this.useFirstPhysicalEvaluationHeight) {
          Api.getFirstPhysicalEvaluation({
            id: this.client.dbId,
            useCache: true,
          }, function (response) {
            if (response.success) {
              const height = response.data[0] && response.data[0].body ? response.data[0].body.height : 0
              for (let i = 0; i < self.details.length; i++) {
                if (self.details[i].id === 'height') {
                  self.details[i].value = height
                  self.details[i].hasValue = true
                  if (response.data[0]) {
                    self.details[i].edit = {
                      type: 'input',
                      phyEval: response.data[0]
                    }
                  }
                  self.$set(self.details, i, self.details[i])
                  break
                }
              }
            }
          })
        } else {
          Api.getLastPhysicalEvaluation({
            id: this.client.dbId,
            useCache: true,
          }, function (response) {
            if (response.success) {
              const height = response.data[0] && response.data[0].body ? response.data[0].body.height : 0
              for (let i = 0; i < self.details.length; i++) {
                if (self.details[i].id === 'height') {
                  self.details[i].value = height
                  self.details[i].hasValue = true
                  if (response.data[0]) {
                    self.details[i].edit = {
                      type: 'input',
                      phyEval: response.data[0]
                    }
                  }
                  self.$set(self.details, i, self.details[i])
                  break
                }
              }
            }
          })
        }
      },
      getClientsStatusDict: function () {
        const statusList = Utils.getUserStatus()
        const dict = {}
        if (statusList && statusList.length) {
          statusList.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      decryptPassword: function () {
        const self = this
        this.$isLoading(true)
        Api.decryptPassword(this.client.password, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.passwordDecrypted = response.data.password
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      checkPayday: function () {
        const today = this.getToday()

        if (this.client.payday && this.client.payday < today) {
          this.$notify({
            group: 'alert',
            title: window.strings['missing_payment'],
            text: window.strings['client'] + ' <b>' + this.client.name + ' (' +
              this.client.mobile_phone + ')</b> ' + window.strings['should_have_paid'] +
              ' <b>' + this.client.payday + '</b>',
            duration: 30000,
          })
        }
      },
      getToday: function () {
        const d = new Date()
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()

        if (month.length < 2) {
          month = '0' + month
        }
        if (day.length < 2) {
          day = '0' + day
        }

        return [year, month, day].join('-')
      },
      refreshFeedbackDateCalc() {
        if (this.showFeedbackCalc) {
          const self = this
          const payload = {
            id: this.client.id
          }
          Api.getAlerts(payload, function (response) {
            if (response.success) {
              for (let i = 0; i < self.details.length; i++) {
                if (self.details[i].id === 'phy_eval') {
                  self.details[i].value = response.data.phy_eval
                  self.$set(self.details, i, self.details[i])
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      refreshContentsAccessValue() {
        const self = this
        Api.getSettings({
          id: this.client.dbId,
        }, function (response) {
          if (response.success) {
            const contentsAccessItem = response.data.find(function (item) {
              return item.name === 'contents_access'
            })
            self.contentsAccessValue = contentsAccessItem && contentsAccessItem.value !== '0' ? true : false
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      changeContentsAccessValue() {
        const self = this
        this.$isLoading(true)
        Api.updateSettings({
          id: this.client.dbId,
          settings: [{
            name: 'contents_access',
            value: this.contentsAccessValue ? '1' : '0'
          }]
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const contentsAccessItem = response.data.find(function (item) {
              return item.name === 'contents_access'
            })
            self.contentsAccessValue = contentsAccessItem &&
              contentsAccessItem.value !== '0' ? true : false
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getClientPhoto: function () {
        const self = this
        Api.getClientsPhotos([this.client.id], function (response) {
          if (response.success && response.data && response.data[0]) {
            self.client.photo = response.data[0].photo
          } else {
            self.client.photo = require('@/assets/user.svg')
          }
        })
      },
      getEmployees: function () {
        const self = this
        Api.getEmployees({
          fields: ['id', 'name']
        }, function (response) {
          if (response.success) {
            let employeesList = [{
              id: null,
              name: ''
            }]
            employeesList = employeesList.concat(response.data)
            self.employeesList = employeesList
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getEmployee: function (id) {
        if (this.employeesList.length) {
          return this.employeesList.find(function (item) {
            return item.id === id
          })
        }
        return null
      },
      openPayday: function () {
        this.dialogPayday = true
        if (this.client.payday === window.strings['n_a']) {
          this.payday = ''
        } else {
          this.payday = this.client.payday
        }
      },
      updatePayday: function (payday) {
        const self = this
        this.dialogPayday = false

        if (this.showUpdatePaydayWithSubscription && this.clientSubscription && this.clientSubscription.has_subscription) {
          this.$confirm(
            window.strings['are_you_sure_update_payday_with_subscription'],
            '',
            'warning',
            Utils.getAlertOptions(true, true)
          ).then(() => {
            update()
          }).catch(() => { })
        } else {
          update()
        }

        function update() {
          Api.updateUser({
            id: self.client.id,
            payday: payday,
          }, function (response) {
            if (response.success) {
              self.client.payday = response.data.payday
              self.registerPayday()
              Utils.setStorage('client', self.client)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      registerPayday: function () {
        const self = this

        if (this.user && this.user.configurations && this.user.configurations.payment && this.user.configurations.payment.payday_record) {
          Api.newPayment({
            db_id: this.client.dbId,
            type: Utils.getPaymentTypeValue('manual'),
            cha_id: this.client.payday,
            promo_code: this.user.name + ' (' + this.user.id + ')',
            status: 1
          }, function (response) {
            if (response.success) {
              if (self.user.configurations && self.user.configurations.client &&
                self.user.configurations.client.indexOf('payments') > -1 &&
                self.paymentsViewPermission
              ) {
                self.parent.refresh('payments')
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      openEditDetails(detail) {
        const self = this
        if (detail.id === 'country' && !detail.edit.items.length) {
          this.$isLoading(true)
          Api.getCountries(function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.details.length; i++) {
                if (self.details[i].id === 'country') {
                  self.details[i].edit.items = response.data
                  break
                }
              }
              open()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          open()
        }
        
        function open() {
          self.detailToEdit = {
            id: detail.id,
            title: detail.title,
            value: detail.hasValue ? detail.value : self.client[detail.id],
            field: detail.edit
          }
          self.dialogDetail = true
        }
      },
      cancelEditDetails() {
        this.dialogDetail = false
        this.detailToEdit = null
      },
      saveEditDetails() {
        const self = this
        const data = {
          id: this.client.id,
        }

        if (!this.detailToEdit) {
          return false
        }

        if (this.detailToEdit.id === 'email') {
          data[this.detailToEdit.id] = this.detailToEdit.value.toLowerCase().trim()
        } else if (this.detailToEdit.field && this.detailToEdit.field.arrayWithCommas) {
          data[this.detailToEdit.id] = this.detailToEdit.value ? this.detailToEdit.value.join(',') : ''
        } else {
          data[this.detailToEdit.id] = this.detailToEdit.value
        }
        this.$isLoading(true)

        if (this.detailToEdit.id === 'height') {
          if (this.detailToEdit.field && this.detailToEdit.field.phyEval) {
            return Api.editPhysicalEvaluation({
              client_id: this.client.dbId,
              id: this.detailToEdit.field.phyEval.id,
              body: {
                height: parseFloat(this.detailToEdit.value)
              }
            }, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.refreshHeightValue()
                self.cancelEditDetails()
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            return self.$alert(
              window.strings['no_physical_evaluations'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        }

        Api.updateUser(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const obj = {}
            obj[self.detailToEdit.id] = response.data[self.detailToEdit.id]
            const clientEncoded = Utils.encodeClient(obj)
            self.client[self.detailToEdit.id] = clientEncoded[self.detailToEdit.id]
            if (self.detailToEdit.field && self.detailToEdit.field.keysToUpdate) {
              self.detailToEdit.field.keysToUpdate.forEach(function (keyToUpdate) {
                self.client[keyToUpdate] = clientEncoded[keyToUpdate]
              })
            }
            Utils.setStorage('client', self.client)
            if (self.detailToEdit.field && self.detailToEdit.field.callback) {
              self.detailToEdit.field.callback()
            }
            if (self.detailToEdit.id === 'email') {
              self.updateUserChatEmail()
            }
            if (self.detailToEdit.id === 'tags') {
              self.updateUserChatTags()
            }
            self.cancelEditDetails()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      updateUserChatEmail() {
        const self = this
        if (this.user && this.user.configurations && this.user.configurations.chat && this.client.chat_id) {
          ChatApi.updateChatUser({
            id: this.client.chat_id,
            email: this.client.email
          }, function (response) {
            if (!response.success) {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      updateUserChatTags() {
        const self = this
        if (this.user && this.user.configurations && this.user.configurations.chat && this.client.chat_id) {
          ChatApi.getChatRoomsIds({
            ids: [this.client.chat_id]
          }, function (resp) {
            if (resp.success) {
              if (resp.data[0]) {
                ChatApi.updateChat({
                  id: resp.data[0].chat_id,
                  tags: self.client.tags ? self.client.tags.join(',') : ''
                }, function (response) {
                  if (!response.success) {
                    self.$alert(
                      response.message,
                      '',
                      'warning',
                      Utils.getAlertOptions()
                    )
                  }
                })
              }
            } else {
              self.$alert(
                resp.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      openClientStatus2Warning: function (client) {
        const message = client.status2 === '#FF0000' ?
          'Precisa de muita motivação desiste muito facilmente!' :
          'Tem motivação mas não sabe qual o treino e alimentação certa para conseguir atingir os resultados'
        this.$alert(
          message,
          '',
          'info',
          Utils.getAlertOptions()
        )
      },
      activateClient() {
        const self = this
        const data = {
          id: this.client.id,
          status: this.statusMap.activateClient,
        }
        if (this.showDefaultWeeklyCardio) {
          data.notes = this.getWeeklyCardioNotes()
        }
        Api.updateUser(data, function (response) {
          if (response.success) {
            self.client.status = response.data.status
            self.client.notes = response.data.notes
            Utils.setStorage('client', self.client)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getWeeklyCardioNotes() {
        let data = {}
        try {
          if (this.client.notes) {
            data = JSON.parse(this.client.notes)
          }
          data.weekly_cardio = 'Cardio: 30min (3x por semana)\n'
          data.weekly_cardio_en = 'Cardio: 30min (3x per week)\n'
          data.weekly_cardio_es = 'Cardio: 30min (3x por semana)\n'
          data.weekly_cardio_fr = 'Cardio: 30min (3x par semaine)\n'
          if (data.training_plan_place === 'Casa') {
            data.weekly_cardio += '- Caminhada Acelerada\n- Cardio a gosto'
            data.weekly_cardio_en += '- Brisk Walking\n- Cardio of choice'
            data.weekly_cardio_es += '- Caminata Rápida\n- Cardio a elección'
            data.weekly_cardio_fr += '- Marche Rapide\n- Cardio au choix'
          }
          if (data.training_plan_place === 'Ginásio') {
            data.weekly_cardio += '- Caminhada Acelerada\n- Bicicleta Estática\n- Passadeira\n- Elíptica'
            data.weekly_cardio_en += '- Brisk Walking\n- Stationary Bike\n- Treadmill\n- Elliptical'
            data.weekly_cardio_es += '- Caminata Rápida\n- Bicicleta Estática\n- Cinta de Correr\n- Elíptica'
            data.weekly_cardio_fr += '- Marche Rapide\n- Vélo Stationnaire\n- Tapis Roulant\n- Elliptique'
          }
        } catch { }
        return JSON.stringify(data)
      },
      openSendNotification() {
        this.notification = {
          title: '',
          body: ''
        }
        this.dialogNotification = true
      },
      closeSendNotification() {
        this.dialogNotification = false
        this.notification = null
      },
      confirmSendNotification() {
        if (this.notificationsModes.length > 1) {
          this.dialogNotificationModes = true
        } else {
          switch (this.notificationsModes[0]) {
            case 'push_notification':
              this.sendPushNotification()
              break;
            case 'internal_notification':
              this.sendInternalNotification()
              break;
            case 'email':
              this.sendEmail()
              break;
          }
        }
      },
      sendPushNotification: function () {
        const self = this
        this.$isLoading(true)
        Api.sendAppNotification({
          pt_id: axios.defaults.ptId,
          title: this.notification.title,
          body: this.notification.body,
          users: [this.client.id]
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.sendNotificationsSuccess()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      sendInternalNotification: function () {
        const self = this
        this.$isLoading(true)
        Api.sendAppManualNotification({
          client_id: this.client.dbId,
          title: this.notification.title,
          body: this.notification.body,
          badge: 0,
          type: 2
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.sendNotificationsSuccess()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      sendEmail: function () {
        const self = this
        this.$isLoading(true)
        Api.sendEmail({
          subject: this.notification.title,
          body: this.notification.body,
          users:  [{
            name: this.client.name,
            email: this.client.email,
          }]
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.sendNotificationsSuccess()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      sendNotificationsSuccess: function () {
        this.closeSendNotification()
        this.dialogNotificationModes = false
        this.$alert(
          window.strings['notification_sent_success'],
          '',
          'success',
          Utils.getAlertOptions()
        )
      },
      openAssignEmployees() {
        this.assignEmployeesData = {
          employee1: this.client.employee1,
          employee2: this.client.employee2,
        }
        if (this.employeesList.length) {
          this.dialogAssignEmployees = true
        }
      },
      closeAssignEmployees() {
        this.dialogAssignEmployees = false
      },
      confirmAssignEmployees() {
        const self = this
        const data = {
          id: this.client.id,
          employee1: this.assignEmployeesData.employee1,
          employee2: this.assignEmployeesData.employee2,
        }
        this.closeAssignEmployees()
        this.$isLoading(true)
        Api.updateUser(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.client.employee1 = response.data.employee1
            self.client.employee2 = response.data.employee2
            Utils.setStorage('client', self.client)
            self.updateUserChatEmployees()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      updateUserChatEmployees() {
        const self = this
        if (this.user && this.user.configurations && this.user.configurations.chat && this.user.configurations.chat.talkjs) {
          const ptTag = Object.keys(PTS_IDS).find(k => PTS_IDS[k] === this.user.pt_id)?.toLowerCase()
          const client = new Talk.User((axios.defaults.dev ? 'dev' : 'prod') + '_' + ptTag + '_' + this.client.id)
          const conversation = window.talkjsSession.getOrCreateConversation(
            Talk.oneOnOneId(window.talkjsPtUser, client)
          )
          const custom = {
            customer_token: this.client.chat_token ? this.client.chat_token : ''
          }
          this.employeesList.forEach((e) => {
            if (e.id) {
              custom['employee_' + e.id] = this.client.employee1 === e.id || this.client.employee2 === e.id ? 'true' : null
            }
          })
          return Api.updateTalkjsConversation({
            id: conversation.id,
            custom: custom
          }, function (response) {
            if (!response.success) {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
        if (this.user && this.user.configurations && this.user.configurations.employees && this.user.configurations.employees.show_chat_clients && this.client.chat_id) {
          ChatApi.getChatRoomsIds({
            ids: [this.client.chat_id]
          }, function (resp) {
            if (resp.success) {
              if (resp.data[0]) {
                const employees = []
                if (self.client.employee1) {
                  employees.push(self.client.employee1)
                }
                if (self.client.employee2) {
                  employees.push(self.client.employee2)
                }
                const notes = self.getNotes()
                notes.employees = employees
                ChatApi.updateChat({
                  id: resp.data[0].chat_id,
                  notes: JSON.stringify(notes)
                }, function (response) {
                  if (!response.success) {
                    self.$alert(
                      response.message,
                      '',
                      'warning',
                      Utils.getAlertOptions()
                    )
                  }
                })
              }
            } else {
              self.$alert(
                resp.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getNotes: function () {
        let data = {}
        try {
          if (this.client.notes) {
            data = JSON.parse(this.client.notes)
          }
        } catch { }
        return data
      },
      openClientChat: function () {
        if (this.user && this.user.configurations && this.user.configurations.chat) {
          const message = this.client.chat_id ? {
            event: 'open-chat-client',
            data: {
              id: this.client.dbId,
              chat_id: this.client.chat_id
            }
          } : {
            event: 'open-new-chat-client',
            data: this.client.dbId
          }
          window.postMessage(JSON.stringify(message), '*')
        } else {
          this.$router.push({ path: '/home/chat/' + this.client.chat_id })
        }
      },
      getClientSubscription() {
        const self = this
        this.$isLoading(true)
        Api.getSubscriptionVW({
          id: this.client.id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.clientSubscription = response.data
          }
        })
      },
      validateInitialFeedback() {
        const self = this
        if (self.user.configurations.dashboard && self.user.configurations.dashboard.indexOf('initial_quizs') > -1) {
          Api.getPhysicalEvaluations({
            id: this.client.dbId,
            orderAsc: false,
            fields: ['id']
          }, function (response) {
            if (response.success && !response.data.length) {
              self.showSendInitialFeedback = true
            }
          })
        }
      },
      sendInitialFeedback() {
        const self = this
        this.$isLoading(true)
        Api.newPhysicalEvaluation({
          client_id: this.client.dbId,
          status: 1
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.showSendInitialFeedback = false
            self.parent.refresh('physical_evaluation')
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      showInformation: function (message) {
        this.$alert(
          message,
          '',
          'info',
          Utils.getAlertOptions()
        )
      },
      copyReturnLink() {
        if (navigator && navigator.clipboard && this.user.configurations.clients.show_back_link) {
          const url = this.user.configurations.clients.show_back_link.url + 'client_id=' + this.client.id + (axios.defaults.dev ? '&dev=true' : '') + (window.jsonAdmin && window.jsonAdmin.linkParameters ? window.jsonAdmin.linkParameters : '')
          const textarea = document.createElement('textarea')
          textarea.value = url
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
          this.$alert(
            window.strings['link_copied'],
            '',
            'success',
            Utils.getAlertOptions()
          )
        }
      },
      copyNewPlansLink() {
        if (navigator && navigator.clipboard && this.user.configurations.clients.show_new_plans_link) {
          const url = this.user.configurations.clients.show_new_plans_link.url + 'client_id=' + this.client.id + (axios.defaults.dev ? '&dev=true' : '') + (window.jsonAdmin && window.jsonAdmin.linkParameters ? window.jsonAdmin.linkParameters : '')
          const textarea = document.createElement('textarea')
          textarea.value = url
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
          this.$alert(
            window.strings['link_copied'],
            '',
            'success',
            Utils.getAlertOptions()
          )
        }
      },
      copyFeedbackLink() {
        if (navigator && navigator.clipboard && this.showNewFeedbackButton && this.showNewFeedbackButton.link) {
          const url = this.showNewFeedbackButton.link.replace('<client_id>', this.client.id) + '&timestamp=' + new Date().getTime() + (axios.defaults.dev ? '&dev=true' : '') + (window.jsonAdmin && window.jsonAdmin.linkParameters ? window.jsonAdmin.linkParameters : '')
          const textarea = document.createElement('textarea')
          textarea.value = url
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
          this.$alert(
            window.strings['link_copied'],
            '',
            'success',
            Utils.getAlertOptions()
          )
        }
      },
      openWhatsapp() {
        if (this.client.mobile_phone) {
          const url = 'https://wa.me/' + this.client.mobile_phone.replace(/\s+/g, '')
          window.open(url, '_blank')
        } else {
          this.$alert(
            window.strings['missing_whatsapp_mobile_number'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      },
      copyWhatsapp() {
        if (this.client.mobile_phone) {
          if (navigator && navigator.clipboard) {
            const url = 'https://wa.me/' + this.client.mobile_phone.replace(/\s+/g, '')
            const textarea = document.createElement('textarea')
            textarea.value = url
            document.body.appendChild(textarea)
            textarea.select()
            document.execCommand('copy')
            document.body.removeChild(textarea)
            this.$alert(
              window.strings['link_copied'],
              '',
              'success',
              Utils.getAlertOptions()
            )
          }
        } else {
          this.$alert(
            window.strings['missing_whatsapp_mobile_number'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      },
      openWhatsappApi() {
        if (this.showWhatsappApi.urls.length > 1) {
          this.dialogSelectWhatsappApi = true
        } else {
          this.selectWhatsappApi(this.showWhatsappApi.urls[0])
        }
      },
      selectWhatsappApi(item) {
        this.dialogSelectWhatsappApi = false
        this.showWhatsappApi.url = item.url
        this.showWhatsappApi.notesKey = item.notes_key
        this.showWhatsappApi.messages = item.messages
        const notes = this.getNotes()
        if (notes && notes[this.showWhatsappApi.notesKey]) {
          this.dialogWhatsappApi = true
        } else {
          this.openWhatsappApiMessage()
        }
      },
      closeWhatsappApi() {
        this.dialogWhatsappApi = false
      },
      openWhatsappApiMessage() {
        const self = this
        const fields = []
        this.showWhatsappApi.fields.forEach(function (f) {
          if (f.id === 'message') {
            fields.push({
              type: 'select',
              id: f.id,
              title: window.strings[f.id] ? window.strings[f.id] : f.id,
              key: f.label,
              items: Utils.getWhatsappMessages(self.showWhatsappApi.messages),
              value: '',
            })
          } else {
            fields.push({
              type: 'input',
              id: f.id,
              title: window.strings[f.id] ? window.strings[f.id] : f.id,
              key: f.label,
              value: self.client[f.id],
              disabled: true,
            })
          }
        })
        this.whatsappApiFields = fields
        this.dialogWhatsappApiMessage = true
        this.closeWhatsappApi()
      },
      closeWhatsappApiMessage() {
        this.dialogWhatsappApiMessage = false
        this.whatsappApiFields = []
      },
      confirmWhatsappApiMessage() {
        const self = this
        const data = {
          url: this.showWhatsappApi.url,
          json: {}
        }
        for (let i = 0; i < this.whatsappApiFields.length; i++) {
          if (!this.whatsappApiFields[i].value) {
            return this.$alert(
              window.strings['please_insert_message'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
          if (this.whatsappApiFields[i].id === 'mobile_phone') {
            let mobilePhoneValue = this.whatsappApiFields[i].value
            if (this.whatsappApiFields[i].value.indexOf('00') === 0) {
              mobilePhoneValue = mobilePhoneValue.slice(2)
            }
            if (this.whatsappApiFields[i].value.indexOf('+') === 0) {
              mobilePhoneValue = mobilePhoneValue.slice(1)
            }
            mobilePhoneValue = mobilePhoneValue.replace(/\s+/g, '')
            data.json[this.whatsappApiFields[i].key] = mobilePhoneValue
          } else {
            data.json[this.whatsappApiFields[i].key] = this.whatsappApiFields[i].value
          }
        }
        this.$isLoading(true)
        Api.postRequest(data, function (response) {
          if (response.success) {
            self.saveWhatsappLink(response.data)            
            self.closeWhatsappApiMessage()
            self.$alert(
              window.strings['message_sent'],
              '',
              'success',
              Utils.getAlertOptions()
            )
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      saveWhatsappLink(data) {
        const self = this
        if (data && data !== 'Accepted') {
          const notes = this.getNotes()
          notes[this.showWhatsappApi.notesKey] = data
          Api.updateUser({
            id: this.client.id,
            notes: JSON.stringify(notes),
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.client.notes = response.data.notes
              Utils.setStorage('client', self.client)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          this.$isLoading(false)
        }
      },
      openWhatsappApiLink() {
        const notes = this.getNotes()
        this.closeWhatsappApi()
        window.open(notes[this.showWhatsappApi.notesKey], '_blank')
      },
      syncSubscription() {
        const self = this
        this.$isLoading(true)
        Api.syncHotmartSubscription(this.client.id, function (response) {
          if (response.success) {
            self.refreshClient()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      refreshClient() {
        const self = this
        Api.getUserWithId({
          id: this.client.id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const client = Utils.encodeClient(response.data[0])
            client.photo = self.client.photo
            Utils.setStorage('client', client)
            self.parent.client = client
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      changePassword() {
        const self = this
        this.$prompt(
          this.str['set_new_password'],
          '',
          '',
          '',
          Utils.getAlertOptions()
        ).then(value => {
          self.$isLoading(true)
          Api.updateUser({
            id: self.client.id,
            password: value,
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.passwordDecrypted = value
              self.client.password = value
              Utils.setStorage('client', self.client)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      openPdf() {
        this.dialogPdfValue = null
        try {
          if (this.client.microcycle) {
            const microcycle = JSON.parse(this.client.microcycle)
            this.dialogPdfValue = microcycle && microcycle.pdfs && microcycle.pdfs[0] && microcycle.pdfs[0].pdf ? microcycle.pdfs[0].pdf : null
          }
        } catch { }
        this.dialogPdf = true
      },
      closePdf() {
        this.dialogPdf = false
        this.dialogPdfValue = null
      },
      loadPdf(e) {
        const self = this
        if (!e.target.files.length) {
          return false
        }
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          self.dialogPdfValue = e.target.result
        }
      },
      removePdf() {
        this.dialogPdfValue = null
      },
      savePdf() {
        const self = this
        let microcycle = this.client.microcycle
        try {
          if (microcycle) {
            microcycle = JSON.parse(microcycle)
          } else {
            microcycle = {}
          }
        } catch (error) {
          microcycle = {}
        }
        microcycle.pdfs = [{
          name: '',
          pdf: this.dialogPdfValue
        }]
        this.$isLoading(true)
        Api.updateUser({
          id: this.client.id,
          microcycle: JSON.stringify(microcycle),
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.client.microcycle = response.data.microcycle
            Utils.setStorage('client', self.client)
            self.closePdf()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      clientFeedbackMounted() {
        this.feedbackRef = this.$refs.feedback
      },
    },
  }
</script>
